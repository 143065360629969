import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Box, Heading, Text, Styled } from "theme-ui"

import widerrufsformular from "../content/misc/widerrufsformular_uschtrin_verlag_042022.pdf"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Widerruf"
      description="Informationen zu Ihrem Widerrufsrecht."
    />
    <Heading as="h1" variant="siteTitle">
      Widerruf
    </Heading>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      Widerrufsbelehrung
    </Heading>
    <Text variant="subheading" sx={{ pt: 2 }}>
      Widerrufsrecht
    </Text>
    <Text variant="default">
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen.
    </Text>
    <Text variant="default">
      Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag
    </Text>
    <Text variant="default">
      <ul style={{ "list-style-type": "none" }}>
        <li>
          – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
          Beförderer ist, die Waren in Besitz genommen haben bzw. hat (Bücher,
          Zeitschriften, sonstige Waren);
        </li>
        <li>
          – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
          Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat (im
          Rahmen einer einheitlichen Bestellung über mehrere Waren, die getrennt
          geliefert werden);
        </li>
        <li>
          – an dem Sie oder ein von Ihnen benannter Dritter, der nicht
          Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz
          genommen haben bzw. hat. (im Rahmen einer Lieferung einer Ware in
          mehreren Teilsendungen oder Stücken);
        </li>
        <li>
          – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
          Beförderer ist, die erste Ware in Besitz genommen haben bzw. hat (im
          Falle eines Vertrags zur regelmäßigen Lieferung von Waren über einen
          festgelegten Zeitraum hinweg [Abonnement]).
        </li>
      </ul>
    </Text>
    <Text variant="default">
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Uschtrin Verlag, Sandra
      Uschtrin, Cincinnatistr. 9, 81549 München, Telefon: +49 89/21528793, E-Mail:
      info@uschtrin.de) mittels einer eindeutigen Erklärung (z. B. ein mit der
      Post versandter Brief, Telefonat oder E-Mail) über Ihren Entschluss,
      diesen Vertrag zu widerrufen, informieren. Sie können dafür das unten
      stehende Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
    </Text>
    <Text variant="default">
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
      die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    </Text>
    <Text variant="subheading" sx={{ pt: 2 }}>
      Folgen des Widerrufs
    </Text>
    <Text variant="default">
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
      andere Art der Lieferung als die von uns angebotene, günstigste
      Standardlieferung gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
      Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
      verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
      Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
      etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis
      wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis
      erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem,
      welches der frühere Zeitpunkt ist.
    </Text>
    <Text variant="default">
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
      vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
      Vertrags unterrichten, an den Uschtrin Verlag, Cincinnatistr. 9, 81549
      München zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie
      die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die
      unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen
      etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf
      einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der
      Waren nicht notwendigen Umgang mit der Ware zurückzuführen ist.
    </Text>
    <Text variant="default">
      Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist
      beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der
      dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
      Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits
      erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
    </Text>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      Widerrufsformular
    </Heading>
    <Text variant="default">
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es an uns zurück. Oder rufen Sie uns einfach
      an.)
    </Text>
    <Box sx={{ p: 4, my: 4, "border-width": "4px", "border-style": "dashed" }}>
      <Text variant="default">
        An
        <br />
        Uschtrin Verlag, Sandra Uschtrin
        <br />
        Cincinnatistr. 9, 81549 München
        <br />
        E-Mail: info@uschtrin.de (Telefon: +49 89/21528793)
      </Text>

      <Text variant="default">
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
        folgenden Dienstleistungen (*):
      </Text>

      <Text variant="default">
        _____________________________________________________________
      </Text>

      <Text variant="default">
        <br />
        Bestellt am (*): _________ / erhalten am (*): _________
        <br />
        <br />
        Name des/der Verbraucher(s):
        <br />
        ______________________________________________
        <br />
        <br />
        Anschrift des/der Verbraucher(s):
        <br />
        ___________________________________________________
        <br />
        ___________________________________________________
        <br />
        ___________________________________________________
        <br />
        ___________________________________________________
        <br />
        <br />
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier):
        <br />
        <br />
        ______________________________________________
        <br />
        <br />
        Datum: _________ <br />
        <br />
        (*) Unzutreffendes bitte streichen
      </Text>
    </Box>

    <Text variant="default">
      Diese Datei können Sie auch hier herunterladen:{" "}
      <Styled.a href={widerrufsformular}>widerrufsformular.pdf</Styled.a>
    </Text>
    <Text variant="default"></Text>
  </Layout>
)

export default Page
